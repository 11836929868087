export default {
    data() {
        return {
            fullHeight: "",
            listHight: null,
            timer: "",
            index: 0,
            listLenght: null,
            timer2: "",
        }
    },
    //页面加载完成,获取dom节点
    mounted() {
        // 多少屏
        this.listLenght = document.querySelectorAll(".list>li").length;
        // 初始化内容去的高度
        this.fullHeight = document.documentElement.clientHeight;
        this.changeFixed(this.fullHeight);

        // 触发窗口大小变化
        window.onresize = () => {
            if (this.$refs.list.style) {
                this.$refs.list.style.top = -this.index * (document.documentElement.clientHeight - 90) + "px";
            }

            return (() => {
                this.fullHeight = document.documentElement.clientHeight;
                // console.log("视口的高度onresize", this.fullHeight);
            })();
        };

        //  滚动条的高度
        window.addEventListener("scroll", this.handleScroll, true);
    },
    watch: {
        // 实时监听页面的变化
        fullHeight: function (val) {
            if (!this.timer) {
                this.fullHeight = val;
                this.changeFixed(this.fullHeight);
                this.timer = true;
                setTimeout(() => {
                    //频繁触发 resize 函数，会导致页面很卡
                    this.timer = false;
                }, 100);
            }
        },
        // 监听目前所处的页面
        index(newVal) {
            // this.index = newVal;
            this.touchMove(newVal);
        },
    },
    destroyed() {
        // 页面销毁时移除监听
        window.removeEventListener("mousewheel", this.onmousewheel);
        window.removeEventListener("DOMMouseScroll", this.onmousewheel);
    },
    // 页面创建时
    created() {
        // window.addEventListener()
        window.addEventListener("mousewheel", this.onmousewheel);
        //  兼容火狐写法
        window.addEventListener("DOMMouseScroll", this.onmousewheel);
    },
    methods: {
        // 页面切换触发动画
        touchMove(index) {
            console.log("indexindexindex", index);
        },
        // 页面滚动触发
        onmousewheel(ev) {
            // console.log("页面触发时滚动", this.timer);
            //  let eve = ev || event;
            clearTimeout(this.timer2);
            // 防止用户在200ms不停滚动鼠标 页面频繁切换
            this.timer2 = setTimeout(() => {
                //必须用箭头函数！！！
                console.log("setTimeoutsetTimeout");
                this.sscollSite(ev);
            }, 200);
        },

        // 滚动设置
        sscollSite(ev) {
            var dir = "";
            if (ev.wheelDelta) {
                dir = ev.wheelDelta > 0 ? "up" : "down";
            } else if (ev.detail) {
                dir = ev.detail < 0 ? "up" : "down";
            }
            switch (dir) {
                case "up":
                    if (this.index > 0) {
                        this.index--;
                        this.move(this.index);
                    }
                    break;
                case "down":
                    if (this.index < this.listLenght - 1) {
                        this.index++;
                        this.move(this.index);
                    }
                    break;
            }
        },

        // 核心动画函数
        move(index) {
            // console.log("核心动画函数", index);
            this.$refs.list.style.top = -index * (document.documentElement.clientHeight - 90) + "px";
        },

        // 动态修改list的高度
        changeFixed(fullHeight) {
            // console.log("content高度", fullHeight);
            this.listHight = `height:${fullHeight - 90}px`;
        },
    }
}