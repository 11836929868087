<template>
  <div
    class="container-title d-flex justify-content-center align-items-center flex-column"
  >
    <div class="title">{{ title }}</div>
    <div class="english">{{english }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    english: String,
  },
};
</script>

<style lang="less" scoped>
.container-title {
  padding-top: 30px;
  padding-bottom: 20px;

  font-size: 32px;
  color: #808cec;
  .english {
    font-size: 18px;
    color: #6f76b0;
  }
}
</style>